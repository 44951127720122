import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import DataProtectionMeasures from "../components/data-protection-measures"
import FeatureColumn from "../components/feature-column"
import Testimonials from "../components/testimonials"
import FeatureObjectTypes from "../components/feature-object-types"
import DeploymentOptions from "../components/deployment-options"
import Demo from "../components/demo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowAltCircleUp,
  faChartLine,
  faEuroSign,
  faArrowRight,
  faBalanceScale,
  faHandshake,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons"
import MoreBlogPosts from "../components/more-blog-posts"

const TerrestrialLaserScanning = ({ data }) => (
  <Layout>
    <SEO
      title="Automated Image Anonymization for Terrestrial Laser Scanning | Celantur"
      description="High-quality and scalable image anonymization solution to automatically blur faces, bodies, license plates on laser scanning imagery"
    />

    <section
      className="container-fluid pt-8 pt-md-12 pb-8 pb-md-12 overlay overlay-black overlay-50 bg-cover text-center"
      style={{
        background: "url(/images/oic-outside-bodies.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <h1
          className="display-2 font-weight-bold text-white"
          style={{ textShadow: "2px 2px 4px #000000" }}
        >
          Automated <span className="text-success">Blurring</span>
          <br />
          for Terrestrial Laser Scanning
        </h1>
        <p
          className="lead mb-6 mb-md-8 text-gray-200"
          style={{ textShadow: "2px 2px 4px #000000" }}
        >
          High-quality and scalable image anonymization solution. Automatically blur faces, bodies, license plates on laser scanning imagery.
        </p>
        <a
          href="https://app.celantur.com/"
          className="btn btn-success lift event-start-demo"
        >
          Start Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
        </a>
        <Link
          to="/contact/"
          className="btn btn-outline-light ml-3 lift event-contact"
        >
          Contact Us
        </Link>
      </div>
    </section>

    <section className="pt-5 pb-8">
      <Customers />
    </section>

    <section className="pt-5 pb-5">
      <div className="container">
        <FeatureObjectTypes />
      </div>
    </section>

    <section className="pt-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 mb-5 text-center">
            <h2 className="font-weight-bold">
              <span className="text-primary">Solve privacy challenges</span>{" "}
              during laser scanning
            </h2>
            <p className="font-size-lg text-muted mb-6">
              Scanning is often time-critical, therefore waiting or non-work
              hours to avoid any employees on imagery is not an option. By
              applying Celantur's automated anonymization, you can fulfill your
              customer's data protection demand while saving time and money.
            </p>
          </div>
        </div>
        <div className="row">
          <FeatureColumn headline="Upselling" icon={faArrowAltCircleUp}>
            Serve your customers with an additional service: Image Blurring.
          </FeatureColumn>
          <FeatureColumn headline="GDPR-compliance" icon={faBalanceScale}>
            Comply with data protection laws around the globe, like the GDPR,
            CCPA, etc.
          </FeatureColumn>
          <FeatureColumn headline="Resolve Privacy Concerns" icon={faHandshake}>
            Resolve privacy concerns of decision-makers, work councils and
            employees.
          </FeatureColumn>
        </div>
        <div className="row">
          <FeatureColumn headline="Save Costs" icon={faEuroSign}>
            Image anonymization happens fully automated, no manual labor
            involved.
          </FeatureColumn>
          <FeatureColumn headline="Less weekend shifts" icon={faCalendarWeek}>
            Map heavily congested areas during work week. Save extra pay for
            weekend shifts.
          </FeatureColumn>
          <FeatureColumn
            headline="Scalable &amp; AI-powered"
            icon={faChartLine}
          >
            Get large projects done with ease. Our machine learning models run
            in the cloud and scale with your needs.
          </FeatureColumn>
        </div>
      </div>
    </section>

    <section className="py-5 py-md-8">
      <div className="container">
        <DeploymentOptions />
      </div>
    </section>

    <Testimonials all={true} />

    <Demo />

    <DataProtectionMeasures />

    <section className="py-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-md-8 text-center">
            <h2 className="font-weight-bold">Frequently Asked Questions</h2>
            <p className="font-size-lg text-muted mb-7 mb-md-9">
              Let us help answer the most common questions you might have
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h3>How long does anonymization usually take?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              Processing time may vary depending on several factors (resolution,
              total amount of objects to anonymize, hardware limitation, etc).
              For example, for 32 MP panorama images, we can anonymize 200.000
              images (cloud) and 20.000 (on-premise) in 24 hours.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3>How can I use it?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              You can use our Cloud Service, where all the processing is done on
              our infrastructure. Or use our Docker container to deploy it
              on-premise or your private/public cloud environment.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3>When to use Cloud Service or the Container?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              It depends on your use case, amount of data, frequency of usage
              and hardware availability. We are happy to help with finding the
              perfect fit for you.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3>How much does your service cost?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              We charge a fee per image or video hour. Here you can create a
              demo account and test it for free. For large projects, our Sales
              Team will assist you with a tailor-made offer based on your
              specific needs.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container text-center text-muted">
        <p>
          Read our article{" "}
          <a
            href="https://www.gim-international.com/content/article/image-anonymization-for-mobile-mapping"
            target="_blank"
            rel="noreferrer"
          >
            "Image Anonymization for Mobile Mapping"
          </a>{" "}
          in GIM International
        </p>
      </div>
    </section>

    <MoreBlogPosts caption="Latest Blog Posts on Surveying" posts={data} />

    <section className="container my-5 text-center">
      {" "}
      {/* CTA */}
      <Link
        to="/contact/"
        role="button"
        className="btn btn-lg btn-success my-2 event-contact"
      >
        Contact Us
        <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
      </Link>
    </section>
  </Layout>
)

export default TerrestrialLaserScanning

export const query = graphql`
  query LaserscanningBlogPosts {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: ["mobile mapping"] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            tags
            path
            teaser
            date
          }
        }
      }
    }
  }
`
